export default function registerDefaultTheme() {

    Theme.register('Default', {
        colors: {

        },
        fonts: {

        }
    });

}