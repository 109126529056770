export default function registerDarkTheme() {

    Theme.register('Dark', {
        colors: {

        },
        fonts: {

        }
    });

}

